<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        :title="title"
        :description="description"
        :hide-articles="true">
        <complete v-if="!active" />
        <non-complete v-else />
        <styled-button
            v-if="active"
            :disabled="!valid"
            class="mt-3"
            small
            @click="goToNextStep">
            Continue
        </styled-button>
    </step-wrapper>
</template>

<script>
import StepWrapper from '@/components/ad-deployment/components/StepWrapper';
import { mapActions } from 'vuex';
import stepProps from '@/components/ad-deployment/steps/stepProps';
import NonComplete from '@/components/ad-deployment/steps/DynamicDeployment/ProductGroupSelection/NonComplete.vue';
import Complete from '@/components/ad-deployment/steps/DynamicDeployment/ProductGroupSelection/Complete.vue';
import StyledButton from '@/components/globals/StyledButton.vue';

export default {
    name: 'ProductGroupSelection',
    components: {
        StyledButton,
        NonComplete,
        Complete,
        StepWrapper,
    },
    props: stepProps,
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        title() {
            return this.$t('adDeployment.productGroupSelection.title');
        },
        description() {
            return this.$t('adDeployment.productGroupSelection.description');
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
    }
};
</script>
