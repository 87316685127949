<template>
    <div class="product-group-selector">
        <icon
            class="mr-5"
            :name="platform.icon"
            size="20"
            color="#8F9EA6" />
        <v-autocomplete
            ref="selectProductGroup"
            v-model="selectedProductGroup"
            :items="productGroups"
            :return-object="true"
            item-text="name"
            item-value="id"
            label="SELECT PRODUCT GROUP"
            :loading="loading"
            required
            chips
            class="styled-field product-group-autocomplete"
            @change="selectsProductGroup">
            <template #item="{ item }">
                <div class="product-group-option">
                    <span>{{ `${item.name}` }}<span
                        v-if="item.count !== null">{{ ` (${item.count} ${suffix(item.count)})` }}</span></span>
                    <div
                        v-if="!item.id"
                        class="sub-text">
                        Built by BuyerBridge
                    </div>
                </div>
            </template>
            <template #selection="{ item }">
                <v-chip small>
                    <span>{{ `${item.name}` }}<span
                        v-if="item.count !== null">{{ ` (${item.count} ${suffix(item.count)})` }}</span></span>
                </v-chip>
            </template>
        </v-autocomplete>
        <div class="icon-container ml-5">
            <a
                :href="linkToPlatform"
                target="_blank"
                class="icon">
                <icon
                    name="outbound-user"
                    size="20"
                    color="#8F9EA6" />
                <span>CREATE NEW GROUP</span>
            </a>
            <div
                class="icon"
                @click="fetchListOfProductGroups">
                <icon
                    name="refresh"
                    size="20"
                    color="#8F9EA6" />
                <span>REFRESH</span>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_SNAPCHAT, PLATFORM_TIKTOK } from '@/helpers/globals';
import { mapMutations, mapState } from 'vuex';
import { getDealerPlatformExternalIds } from '@/components/onboarding/channel-setup/channels';

export default {
    name: 'PlatformProductGroupSelector',
    components: {
        Icon
    },
    props: {
        platform: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            defaultProductGroups: {
                [PLATFORM_FACEBOOK]: {
                    id: null,
                    name: 'All Inventory',
                    count: null,
                },
                [PLATFORM_TIKTOK]: {
                    id: null,
                    name: 'All Inventory',
                    count: null,
                },
                [PLATFORM_SNAPCHAT]: {
                    id: null,
                    name: 'All Inventory',
                    count: null,
                },
                [PLATFORM_PINTEREST]: {
                    id: null,
                    name: 'New Only',
                    count: null,
                }
            },
            apiProductGroups: [],
            loading: false,
        };
    },
    computed: {
        ...mapState({
            deploymentDealerId: (state) => state.adDeployment.dealer_id,
            currentDealer: (state) => state.dealer.currentDealer,
        }),
        productGroups() {
            return [
                this.defaultProductGroup,
                ...this.apiProductGroups
            ];
        },
        defaultProductGroup() {
            return this.defaultProductGroups[this.platform.name];
        },
        selectedProductGroup: {
            get() {
                return this.$store.state.adDeployment.product_group_selection.platform_specific[this.platform.name];
            },
            set(value) {
                if (value?.id) {
                    this.$emit('set-radio-custom');
                }

                this.setAdDeploymentValue({
                    key: `product_group_selection.platform_specific.${this.platform.name}`,
                    value
                });
            }
        },
        dealerPlatformInfo() {
            return getDealerPlatformExternalIds(this.currentDealer, this.platform.name);
        },
        catalogId() {
            return this.dealerPlatformInfo.catalogId;
        },
        businessManagerId() {
            return this.dealerPlatformInfo.businessManagerId;
        },
        adAccountId() {
            return this.dealerPlatformInfo.adAccountId;
        },
        linkToPlatform() {
            switch (this.platform.name) {
                case PLATFORM_FACEBOOK:
                    return `https://business.facebook.com/commerce/catalogs/${this.catalogId}/sets/create?business_id=${this.businessManagerId}&originLocation=SETS_PAGE&createSetMethod=Dynamic`;
                case PLATFORM_TIKTOK:
                    return `https://ads.tiktok.com/catalogs/product-sets?aadvid=${this.adAccountId}&id=${this.catalogId}&bc_id=${this.businessManagerId}`;
                case PLATFORM_PINTEREST:
                    return `https://www.pinterest.com/business/catalogs/${this.catalogId}/product-groups/?advertiserId=${this.adAccountId}&feedProfileId`;
                case PLATFORM_SNAPCHAT:
                    return `https://business.snapchat.com/${this.businessManagerId}/catalogs/${this.catalogId}/items`;
                default:
                    return '';
            }
        }
    },
    created() {
        this.selectedProductGroup = this.defaultProductGroup;

        this.fetchListOfProductGroups();
        this.getDefaultProductGroupCount();

        this.$parent.$on('show-default-product-group', () => {
            this.selectedProductGroup = this.productGroups?.[0];
            this.selectsDefaultProductGroup();
        });
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue',
        }),
        selectsProductGroup(value) {
            this.selectedProductGroup = value;
        },
        selectsDefaultProductGroup() {
            this.selectedProductGroup = this.productGroups?.[0];
            this.$refs?.selectProductGroup?.selectItem(this.productGroups?.[0]);
        },
        suffix(count) {
            return count > 1 ? 'vehicles' : 'vehicle';
        },
        async fetchListOfProductGroups() {
            try {
                this.loading = true;
                this.apiProductGroups = await this.$apiRepository.getDealerProductGroups(this.deploymentDealerId, this.platform.name);
            } catch (e) {
                console.log(e);
            } finally {
                this.loading = false;
            }
        },
        async getDefaultProductGroupCount() {
            const aggregationKey = `exports.${this.platform.name}_auto.is_vehicle_eligible`;

            const payload = {
                q: '',
                filters: {
                    dealer_id: this.deploymentDealerId
                },
                aggregations: [aggregationKey],
            };

            const { data: { aggregations } } = await this.$apiRepository.getInventoryVehicles(payload);

            const eligibleCount = aggregations[aggregationKey]?.find(aggregationObj => {
                return aggregationObj?.key === 'true';
            });

            this.productGroups[0].count = eligibleCount?.count;
            this.selectsDefaultProductGroup();
        }
    }
};
</script>

<style lang="scss" scoped>
.product-group-selector {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product-group-autocomplete {
        max-width: 443px;
    }

    .icon-container {
        display: flex;

        a {
            text-decoration: none;
        }

        .icon {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 18px;

            &:hover {
                cursor: pointer;
            }

            span {
                white-space: nowrap;
                font-size: 9px;
                font-weight: 400;
                color: #3F4649;
            }
        }
    }


}
</style>

<style lang="scss">
.product-group-selector {
    .v-select__selections {
        height: 32px !important;

        span {
            color: #4E555B;
        }
    }
}

.v-select-list .product-group-option {
    display: flex;
    flex-direction: column;

    span {
        color: #9B9B9B;
    }

    .sub-text {
        font-size: 12px;
        color: #9B9B9B;
    }
}
</style>
