<template>
    <div class="product-group-exhibition">
        <icon
            class="mr-3"
            :name="platform.icon"
            size="20"
            color="#8F9EA6" />
        <div class="header">
            <span class="header__title">SET</span>
            <span class="header__name">{{ productGroupName }}</span>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon.vue';

export default {
    name: 'PlatformProductGroupExhibition',
    components: {
        Icon
    },
    props: {
        platform: {
            type: Object,
            required: true,
        }
    },
    computed: {
        productGroup() {
            return this.$store.state.adDeployment.product_group_selection.platform_specific[this.platform.name];
        },
        productGroupName() {
            if (this.productGroup?.count !== null) {
                return `${this.productGroup?.name} ${this.vehiclesSuffix(this.productGroup?.count)}`;
            }

            return this.productGroup?.name;
        }
    },
    methods: {
        vehiclesSuffix(count) {
            const suffix = count > 1 ? 'vehicles' : 'vehicle';

            return `(${count} ${suffix})`;
        },
    }
};
</script>

<style lang="scss" scoped>
.product-group-exhibition {
    width: 350px;
    display: flex;
    align-items: center;

    .header {
        width: 350px;
        height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: white;
        padding: 10px 20px 10px 15px;
        border: 1px solid #DFDFE0;

        &__title {
            font-weight: 700;
            margin-right: 15px;
        }

        &__name {
            color: #03A2EA;
            font-style: italic;
        }
    }

}
</style>
