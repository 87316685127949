<template>
    <div class="complete-container">
        <div class="header">
            <div class="step mr-2">
                <span class="step__title">PRODUCT GROUP</span>
                <span class="step__info">{{ translateRadioGroupOption }}</span>
            </div>
            <edit-button step="product_group_selection" />
        </div>
        <platform-product-group-exhibition
            v-for="(platform, index) in activePlatforms"
            :key="index"
            :platform="platform" />
    </div>
</template>

<script>
import PlatformProductGroupExhibition from '@/components/ad-deployment/steps/DynamicDeployment/ProductGroupSelection/PlatformProductGroupExhibition/index.vue';
import { mapGetters, mapState } from 'vuex';
import EditButton from '@/components/ad-deployment/components/EditButton.vue';

export default {
    name: 'ProductGroupSelectionComplete',
    components: {
        PlatformProductGroupExhibition,
        EditButton
    },
    computed: {
        ...mapState({
           radioGroupOption: (state) => state.adDeployment.product_group_selection.radio_group_option,
        }),
        ...mapGetters({
            activePlatforms: 'adDeployment/activePlatforms',
        }),
        translateRadioGroupOption() {
            if (this.radioGroupOption === 'default') {
                return 'All Inventory';
            }

            return 'Customize';
        }
    }
};
</script>

<style lang="scss" scoped>
.complete-container {
    .header {
        display: flex;

        .step {
            width: 350px;
            display: flex;
            justify-content: space-between;
            background: white;
            padding: 10px 20px 10px 15px;
            border: 1px solid #DFDFE0;
            margin-bottom: 26px;

            &__title {
                font-weight: 700;
                margin-right: 15px;
            }

            &__info {
                color: #03A2EA;
                font-style: italic;
            }
        }
    }

    .product-group-exhibition {
        margin-bottom: 7px;
    }
}

</style>
