<template>
    <div class="wrapper">
        <div class="header">
            <p>Product Groups</p>
            <div class="radio-container">
                <div class="mr-4">
                    <div class="all-inventory">
                        <styled-radio
                            class="mr-2"
                            :value="radioValue"
                            input-value="default"
                            label="All Inventory"
                            name="defaultProductGroupSelection"
                            dot-style
                            inline
                            @input="selectsRadioOption('default')" />
                        <span>(Recommended)</span>
                    </div>
                    <ad-deployment-tooltip content-path="adDeployment.productGroupSelection.tooltips.default" />
                </div>
                <div class="mr-4">
                    <styled-radio
                        class="mr-2"
                        :value="radioValue"
                        input-value="custom"
                        label="Customize"
                        name="customProductGroupSelection"
                        dot-style
                        inline
                        @input="selectsRadioOption('custom')" />
                    <ad-deployment-tooltip content-path="adDeployment.productGroupSelection.tooltips.custom" />
                </div>
                <div
                    class="show-hide"
                    @click="showContent = !showContent">
                    <icon
                        :name="icon"
                        size="20"
                        color="#8F9EA6" />
                </div>
            </div>
        </div>
        <div
            v-show="hasPinterestInDeployment && !showContent && radioValue === 'default'"
            class="warning-pinterest">
            <icon
                class="mr-2"
                name="indicator-warning"
                color="#FF3D00"
                size="18" />
            <div>
                {{ $t('adDeployment.productGroupSelection.misc.pinterest') }}
            </div>
        </div>
        <div
            v-show="showContent"
            class="body">
            <product-group-selector
                v-for="(platform, index) in activePlatforms"
                :key="index"
                class="mb-4"
                :platform="platform"
                @set-radio-custom="selectsRadioOption('custom')" />
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import StyledRadio from '@/components/globals/StyledRadio.vue';
import ProductGroupSelector from '@/components/ad-deployment/steps/DynamicDeployment/ProductGroupSelection/PlatformProductGroupSelector/index.vue';
import Icon from '@/components/globals/Icon.vue';
import AdDeploymentTooltip from '@/components/ad-deployment/components/AdDeploymentTooltip.vue';
import { PLATFORM_PINTEREST } from '@/helpers/globals';

export default {
    name: 'ProductGroupSelectionNonComplete',
    components: { Icon, ProductGroupSelector, StyledRadio, AdDeploymentTooltip },
    data() {
        return {
            showContent: false,
        };
    },
    computed: {
        ...mapGetters({
            activePlatforms: 'adDeployment/activePlatforms'
        }),
        radioValue: {
            get() {
                return this.$store.state.adDeployment.product_group_selection.radio_group_option;
            },
            set(value) {
                this.setAdDeploymentValue({
                    key: 'product_group_selection.radio_group_option',
                    value
                });
            }
        },
        icon() {
            return this.showContent ? 'chevron-up' : 'chevron-down';
        },
        hasPinterestInDeployment() {
            return this.activePlatforms.includes(PLATFORM_PINTEREST);
        }
    },
    created() {
      if (this.radioValue === 'custom') {
        this.showContent = true;
      }
    },
    methods: {
        ...mapMutations({
            setAdDeploymentValue: 'adDeployment/setAdDeploymentValue'
        }),
        selectsRadioOption(option) {
            if (option === 'default') {
                this.$emit('show-default-product-group');
            }

            this.radioValue = option;
            this.showContent = true;
        }
    }
};
</script>

<style lang="scss" scoped>
    .wrapper {
        background-color: $white;
        border: 1px solid #DEDEDE;

        .header {
            position: relative;
            padding: 26px 14px 26px 14px;
            border-bottom: 1px solid #DEDEDE;
            display: flex;
            flex-direction: row;
            align-items: center;

            p {
                font-size: 14px;
                color: #4A4A4A;
                font-weight: 600;
                margin: 0;
            }

            .radio-container {
                display: flex;
                margin-left: 78px;

                div {
                    display: flex;
                }

                .all-inventory {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 11px;

                    span {
                        font-size: 11px;
                        color: #9B9B9B;
                        margin-left: 20px;
                    }
                }

                .inline {
                    margin-bottom: 0;
                }
            }

            .show-hide {
                border-left: 1px solid #DEDEDE;
                top: 0;
                width: 71px;
                right: 0;
                bottom: 0;
                position:absolute;
                align-items: center;
                justify-content: center;

                &:hover {
                    cursor: pointer;
                }
            }
        }

        .warning-pinterest {
            display: flex;
            background-color: #FFCFC0;
            padding: 5px 10px;
            height: 46px;
            align-items: center;
        }
        .body {
            padding: 20px 23px 15px 23px;
        }
    }
</style>
